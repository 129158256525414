import { h, Component } from 'preact';

export class Symbol extends Component {
  render({ symbolUrl }) {
    // Can we get a better description of the symbols to aid aria-label?

    const handleError = (event) => {
      event.target.style.display = 'none';
    }

    return (
      <img
        src={symbolUrl}
        aria-label="Symbol valgt på vegne av avdøde."
        title="Symbol valgt på vegne av avdøde."
        className="symbol__img"
        onError={handleError}
      />
    );
  }
}
