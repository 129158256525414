function getMonth(month, year) {
  const today = new Date(`${year}/${month + 1}/01`);
  let variableDate = new Date(today);

  let dateList = [];
  while (variableDate.getDate() <= today.getDate()) {
    dateList.push(variableDate.getDate());
    variableDate.setDate(variableDate.getDate() - 1);
  }
  variableDate = new Date(today);
  variableDate.setDate(variableDate.getDate() + 1);
  while (variableDate.getDate() > today.getDate()) {
    dateList.push(variableDate.getDate());
    variableDate.setDate(variableDate.getDate() + 1);
  }
  return dateList;
}
function getFirstOfPreviousMonth(month, year) {
  let variableDate = new Date(`${year}/${month + 1}/01`);
  variableDate.setMonth(variableDate.getMonth() - 1);
  return variableDate;
}
function getFirstOfNextMonth(month, year) {
  let variableDate = new Date(`${year}/${month + 1}/01`);
  variableDate.setMonth(variableDate.getMonth() + 1);
  return variableDate;
}
function getCurrent(date = new Date()) {
  return {
    month: date.getMonth(),
    year: date.getFullYear(),
    date: date.getDate(),
  };
}
function getMonthName(month) {
  const months = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
  return months[month];
}

function getDayName(day) {
  const days = [
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag',
    'Søndag',
  ];
  return days[day];
}

function whichDayIsFirst(month, year) {
  const today = new Date(`${year}/${month + 1}/01`);
  return today.getDay();
}

export {
  getMonth,
  getFirstOfPreviousMonth,
  getFirstOfNextMonth,
  getCurrent,
  getMonthName,
  getDayName,
  whichDayIsFirst,
};
