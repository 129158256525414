// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container__1THYQ{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:26px}.container__1THYQ label{cursor:pointer;padding-left:20px}.container__1THYQ input ~ label:after{background:white;border:1px solid #ccc;border-radius:50%;cursor:pointer;content:'';left:0;top:0;position:absolute;width:20px;height:20px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.container__1THYQ input:checked ~ label:after{content:'✓';color:white;background:#2f74ab}.container__1THYQ input[type='checkbox']{opacity:0}.container__1THYQ input[type='checkbox']:focus+label{outline:-webkit-focus-ring-color auto 5px}\n", ""]);
// Exports
exports.locals = {
	"container": "container__1THYQ"
};
module.exports = exports;
