import { h, Component } from 'preact';
import { getApiUrl } from '../../utils/hostname';

export function withLocations(ComposedComponent) {
  class WrapperComponent extends Component {
    state = {
      locations: [],
    };

    fetchData = () => {
      return fetch(`${getApiUrl()}/api/locations`, {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(locations => {
          if (
            locations.hasOwnProperty('data') &&
            Array.isArray(locations.data)
          ) {
            this.setState({ locations: locations.data.sort() });
          } else {
            console.warn('Error fetching obituaries: ', locations);
          }
        });
    };

    componentDidMount() {
      return this.fetchData();
    }

    render(props, state) {
      return <ComposedComponent {...props} locations={state.locations} />;
    }
  }

  return WrapperComponent;
}
