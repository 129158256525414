import { h, Component } from 'preact';
import styles from './style.scss';

export class Citation extends Component {
  render(props) {
    return (
      props.citation && (
        <div className={styles.citation}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.citation.text.replace(/\n/g, '<br>'),
            }}
          ></div>
          {props.citation.author && (
            <div
              className={styles.author}
              dangerouslySetInnerHTML={{
                __html: props.citation.author.replace(/\n/g, '<br>'),
              }}
            ></div>
          )}
        </div>
      )
    );
  }
}
