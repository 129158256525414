import { h, Component } from 'preact';
import { Symbol } from '../symbol';
import { FamilyMembers } from '../family-members';
import formatDate, { formatDateNumbers } from '../../utils/formatDate';
import styles from './style.scss';
import { Citation } from '../citation';

const getByPosition = function(data, position) {
  return data.find(entity => entity.position === position);
};

export class Obituary extends Component {
  render(props, state) {
    const obituary = props.data;

    return (
      <div key={obituary.id} className={styles.item}>
        <div className={styles.content}>
          <Symbol symbolUrl={obituary.symbol_url} />
          <p
            className={styles.headline}
            dangerouslySetInnerHTML={{
              __html: obituary.headline?.replace(/\n/g, '<br>'),
            }}
          ></p>
          <div className={styles.nameContainer}>
            <h1
              className={styles.name}
              dangerouslySetInnerHTML={{
                __html: obituary.person_full_name.replace(/\n/g, '<br>'),
              }}
            ></h1>
            <span className={styles.maidenName}>
              {obituary.person_maiden_name
                ? `f. ${obituary.person_maiden_name}`
                : null}
            </span>
          </div>
          <div>
            {obituary.person_birth_date
              ? `født ${formatDate(obituary.person_birth_date)}`
              : null}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: obituary.explanation.replace(/\n/g, '<br>').replace(/\\n/g, '<br>'),
            }}
          ></div>
          {obituary.death_place && <div>{obituary.death_place}</div>}
          <div>{formatDate(obituary.person_death_date)}</div>
          <Citation citation={getByPosition(obituary.citations, 1)}></Citation>
          <FamilyMembers
            familyMembers={obituary.family_members}
            source_name={obituary.source_name}
          />
          <Citation citation={getByPosition(obituary.citations, 2)}></Citation>
          <p>{obituary.footnote}</p>
          <p>{obituary.notice}</p>
          {obituary.additional_symbol_url ? (
            <Symbol symbolUrl={obituary.additional_symbol_url} />
          ) : null}
          {obituary.additional_headline ? (
            <p className={styles.headline}>{obituary.additional_headline}</p>
          ) : null}
          {obituary.additional_person_name ? (
            <div className={styles.nameContainer}>
              <h1 className={styles.name}>{obituary.additional_person_name}</h1>
            </div>
          ) : null}
          {obituary.additional_explanation ? (
            <div>{obituary.additional_explanation}</div>
          ) : null}

          {obituary.additional_family_members && (
            <FamilyMembers
              familyMembers={obituary.additional_family_members}
              source_name={obituary.source_name}
            />
          )}
          <p>{obituary.additional_footnote}</p>
          <p>{obituary.additional_notice}</p>
          {obituary.agency_name && (
            <p className={styles.agencyName}>{obituary.agency_name}</p>
          )}
          <div className={styles.paperInfo}>
            {obituary.person_city}{' '}
            {formatDateNumbers(obituary.publication_date, '-')}
          </div>
          {obituary.funeral_agency_name && (
            <div className={styles.funeralAgencyName}>{obituary.funeral_agency_name}</div>
          )}
          <div className={styles.actions}>
            {obituary.order_flowers_url && (
              <div className={styles.actions__item}>
                <a href={obituary.order_flowers_url} target="_blank">
                  Bestill blomster
                </a>
              </div>
            )}
            {obituary.memorial_page_url && (
              <div
                className={`${styles.actions__item} ${styles['actions__item--right']}`}
              >
                <a href={obituary.memorial_page_url} target="_blank">
                  Minneside
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
