export const filterEmptyValues = obj =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) acc[key] = value;

    return acc;
  }, {});

export const filterByAllowedKeys = (obj, keys) =>
  Object.keys(obj)
    .filter(item => keys.indexOf(item) >= 0)
    .reduce((acc, key) => {
      acc[key] = obj[key];

      return acc;
    }, {});
