import { h, Component } from 'preact';
import styles from './style.scss';
import { RoundedCheckbox } from '../form/rounded-checkbox';

export class CheckboxSelect extends Component {
  render(props, {}) {
    return (
      <div className={styles.container}>
        {props.options.map(option => (
          <RoundedCheckbox
            onChange={() => props.onChange(option.value)}
            checked={props.selected === option.value}
            id={`checkbox-${option.value}`}
            label={option.label}
          />
        ))}
      </div>
    );
  }
}
