import { h, Component } from 'preact';
import queryString from 'query-string';
import { filterEmptyValues, filterByAllowedKeys } from '../../utils/object';

const allowedKeys = [
  'id',
  'name',
  'start',
  'end',
  'city',
  'publication',
  'advanced',
  'searchFullText',
  'dateType',
];

export function withFilters(ComposedComponent) {
  class WrapperComponent extends Component {
    constructor(props) {
      super(props);

      const { query } = queryString.parseUrl(window.parent.location.href);

      const initialState = filterByAllowedKeys(query, allowedKeys);

      this.state = {
        id: null,
        name: '',
        start: '',
        end: '',
        city: '',
        advanced: false,
        publication: props.publication,
        ...initialState,
      };
    }

    setSearchParams = () => {
      const parsed = queryString.parseUrl(window.parent.location.href);

      const query = {
        ...parsed.query,
        ...this.state,
      };

      const filteredParameters = filterEmptyValues(query);
      const url = Object.keys(filteredParameters).length
        ? `${parsed.url}?${queryString.stringify(filteredParameters)}`
        : parsed.url;

      window.parent.history.replaceState({}, '', url);
    };

    handleUpdateState = state => {
      this.setState(state, this.setSearchParams);
    };

    render(props, state) {
      return (
        <ComposedComponent
          {...props}
          filters={state}
          updateFilters={this.handleUpdateState}
        />
      );
    }
  }

  return WrapperComponent;
}
