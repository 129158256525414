import { h, Component } from 'preact';
import * as utils from './utils';
import styles from './style.scss';

const daysInWeek = 7;

export class DatePicker extends Component {
  constructor(props) {
    super(props);
    const current = utils.getCurrent();
    this.state = {
      initial: current,
      year: current.year,
      month: current.month,
      date: current.date,
      startDate: null,
      hoverDate: null,
      selecting: false
    };
  }

  previous = () => {
    const previousDate = utils.getFirstOfPreviousMonth(
      this.state.month,
      this.state.year
    );
    const previous = utils.getCurrent(previousDate);
    this.setState({
      month: previous.month,
      year: previous.year,
    });
  };

  next = () => {
    const previous = utils.getCurrent(
      utils.getFirstOfNextMonth(this.state.month, this.state.year)
    );
    this.setState({
      month: previous.month,
      year: previous.year,
    });
  };

  dateClick = e => {
    if (this.state.selecting) {
      const endDate = new Date(e.target.getAttribute('data-date'));
      if (this.state.startDate > endDate) {
        this.props.onDateSelect(endDate, this.state.startDate);
      } else {
        this.props.onDateSelect(this.state.startDate, endDate);
      }
      this.setState({
        active: false,
        startDate: null,
        selecting: false,
      });
    } else {
      this.setState({
        startDate: new Date(e.target.getAttribute('data-date')),
        hoverDate: new Date(e.target.getAttribute('data-date')),
        selecting: true,
      });
    }
  };

  dateHover = e => {
    if (this.state.selecting) {
      this.setState({
        hoverDate: new Date(e.target.getAttribute('data-date')),
      });
    }
  };

  drawDates = (days, state) => {
    if (state.selecting) {
      return days.map(d => {
        const date = new Date(`${state.year}/${state.month + 1}/${d}`);
        let start = date.getTime() === state.startDate.getTime();
        let end = state.hoverDate
          ? date.getTime() === state.hoverDate.getTime()
          : false;

        let hovered;
        if (state.startDate > state.hoverDate) {
          const temp = start;
          start = end;
          end = temp;
          hovered = date < state.startDate && date > state.hoverDate;
        } else {
          hovered = date > state.startDate && date < state.hoverDate;
        }

        const cssClasses = `${styles.day}
          ${start ? styles.start : ''}
          ${end && !start ? styles.end : ''}
          ${hovered ? styles.selected : ''}`;
        return (
          <td
            onMouseEnter={this.dateHover}
            onClick={this.dateClick}
            data-date={`${state.year}/${state.month + 1}/${d}`}
            className={cssClasses}>
            {d}
          </td>
        );
      });
    }
    return days.map(d => (
      <td
        onMouseEnter={this.dateHover}
        onClick={this.dateClick}
        data-date={`${state.year}/${state.month + 1}/${d}`}
        className={styles.day}>
        {d}
      </td>
    ));
  };

  render(props, state) {
    const days = utils.getMonth(state.month, state.year);
    const startDay = utils.whichDayIsFirst(state.month, state.year);

    const initialSpans = [];

    if(startDay){
      for (let i = 0; i < startDay - 1; i++) {
        initialSpans.push(<td className={styles.day} key={`initial${i}`} />);
      }
    } else {
      for (let i = startDay; i < daysInWeek - 1; i++) {
        initialSpans.push(<td className={styles.day} key={`initial${i}`} />);
      }
    }

    const daySpans = [];

    for (let i = 0; i < daysInWeek; i++) {
      daySpans.push(
        <td className={styles.dayHeader} key={`dayheader-${i}`}>
          {utils.getDayName(i).substr(0, 3)}
        </td>
      );
    }

    const monthName = utils.getMonthName(state.month);

    const dates = this.drawDates(days, state);

    const rows = [];
    let currentRow = [];
    let counter = 0;
    for (let i = 0; i < initialSpans.length + dates.length; i++) {
      if (i >= initialSpans.length) {
        currentRow.push(dates[i - initialSpans.length]);
      } else {
        currentRow.push(initialSpans[i]);
      }
      counter++;
      if (
        counter >= daysInWeek ||
        i === initialSpans.length + dates.length - 1
      ) {
        counter = 0;
        rows.push(currentRow);
        currentRow = [];
      }
    }
    while (counter < daysInWeek) {
      currentRow.push(
        <td className={styles.day} key={`placeholder${counter}`} />
      );
      counter++;
    }

    return (
      <div className={`${styles.container} ${props.active ? styles.show : ''}`}>
        <div className={styles.header}>
          <button onClick={this.previous}>{'<'}</button>
          <span>
            {monthName} {state.year}
          </span>
          <button onClick={this.next}>{'>'}</button>
        </div>
        <table className={styles.dates}>
          <thead><tr>{daySpans}</tr></thead>
          <tbody>
            {rows.map(row => (
              <tr>{row}</tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
