import { h, Component } from 'preact';
import { Advanced } from './advanced';
import styles from './style.scss';

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvanced: props.initialState.advanced === 'true' || false,
      searchString: props.initialState.name || '',
      // Will be initialized by Advanced componentDidMount to the default state.
      advanced: { ...props.initialState }
    };
  }
  toggleAdvanced = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showAdvanced: !this.state.showAdvanced,
    });
  };
  search = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSubmit({
      ...this.state.advanced,
      name: this.state.searchString,
      advanced: this.state.showAdvanced,
    });
  };

  onAdvancedChange = advancedState => {
    this.setState({
      advanced: advancedState,
    });
  };

  changeSearchString = e => {
    this.setState({
      searchString: e.target.value,
    });
  };

  resetSearchString = () => {
    this.setState({
      searchString: '',
    });
  };

  render(props, state) {
    return (
      <div className={styles.container}>
        <form onSubmit={this.search}>
          <div className={styles.centerSearch}>
            <div className={styles.positioner}>
              <input
                aria-label="Søk etter navn"
                placeholder="Søk etter navn"
                type="text"
                value={state.searchString}
                onChange={this.changeSearchString}
                className={styles.mainInput}
              />

              <button
                className={`${styles.iconButton} ${styles.searchButton}`}
                aria-label="Søk"
                type="submit">
                <span className={`${styles.icon} ${styles.search}`} />
              </button>
              <button
                aria-label="Vis avanserte søkealternativer"
                className={`${styles.iconButton} ${styles.advancedButton}`}
                aria-controls="expandedSearch"
                aria-expanded={state.showAdvanced}
                onClick={this.toggleAdvanced}>
                <span
                  aria-hidden="true"
                  className={`${styles.icon} ${styles.advancedBg}`}
                />
              </button>
            </div>
            {state.showAdvanced ? (
              <Advanced
                toggleSearch={this.toggleAdvanced}
                search={this.search}
                id="expandedSearch"
                onStateChange={this.onAdvancedChange}
                publications={props.publications}
                initialState={props.initialState}
                resetSearchString={this.resetSearchString}
              />
            ) : (
              <div className={styles.tips}>
                Tips! Åpne
                <span className={`${styles.smallIcon} ${styles.advancedBg}`} />
                avansert søk for å søke på spesifikk periode eller sted.
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}
