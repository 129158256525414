import { h, Component } from 'preact';
import styles from './rounded-checkbox.scss';

export class RoundedCheckbox extends Component {
  render(props, state) {
    // Maybe label can be props.children instead of a text prop.
    // <RoundedCheckbox ...>Label text here</RoundedCheckbox>
    return (
      <div className={styles.container}>
        <input
          onChange={props.onChange}
          checked={props.checked}
          type="checkbox"
          id={props.id}
        />
        <label for={props.id}>{props.label}</label>
      </div>
    );
  }
}
