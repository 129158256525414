import { h, Component } from 'preact';
import { RoundedCheckbox } from './rounded-checkbox';
import { CheckboxSelect } from '../checkbox-select';
import {
  formatDateISO,
  stringToDateObject,
  formatDateNumbers,
} from '../../utils/formatDate';
import { DatePicker } from '../date-picker';
import styles from './advanced.scss';

export class Advanced extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: props.initialState.start ? formatDateNumbers(props.initialState.start) : null,
      end: props.initialState.end ? formatDateNumbers(props.initialState.end) : null,
      dateType: props.initialState.dateType || '',
      publication:props.initialState.publication || props.publications[0].value,
      city: props.initialState.city || '',
      searchFullText: props.initialState.searchFullText === 'true' || false,
      showDatePicker: false
    };
  }

  onDateSelect = (start, end) => {
    this.updateState({
      start: formatDateNumbers(start),
      end: formatDateNumbers(end),
      showDatePicker: false,
    });
  };

  componentDidMount = () => {
    this.onStateChange();
  };
  onStateChange = () => {
    const params = {
      ...this.state,
      start: formatDateISO(stringToDateObject(this.state.start)),
      end: formatDateISO(stringToDateObject(this.state.end)),
    };
    this.props.onStateChange(params);
  };
  updateState = newStateValues => {
    this.setState(newStateValues, this.onStateChange);
  };
  changePublication = newPublication => {
    this.updateState({
      publication: newPublication,
    });
  };
  toggleDatePicker = () => {
    this.setState({
      showDatePicker: !this.state.showDatePicker,
    });
  };
  changeStart = e => {
    this.updateState({
      start: e.target.value,
    });
  };
  changeEnd = e => {
    this.updateState({
      end: e.target.value,
    });
  };
  changeCity = e => {
    this.updateState({
      city: e.target.value,
    });
  };

  resetSearch = e => {
    e.stopPropagation();
    e.preventDefault();
    this.props.resetSearchString();
    this.updateState({
      start: null,
      end: null,
      publication: this.props.publications[0].value,
      city: '',
      searchFullText: false,
    });
  };
  onSearch = e => {
    //console.log(this.state);
  };

  onToggleSearchFullText = e => {
    this.updateState({
      searchFullText: e.target.checked,
    });
  };

  placeholder = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  filterByDate = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    let newStart = new Date();
    let newEnd = new Date();
    switch (type) {
      case 'today': {
        break;
      }
      case 'yesterday': {
        newStart.setDate(newStart.getDate() - 1);
        newEnd.setDate(newEnd.getDate() - 1);
        break;
      }
      case 'week': {
        newStart.setDate(newStart.getDate() - 7);
        break;
      }
      case 'lastweek': {
        newStart.setDate(newStart.getDate() - 14);
        newEnd.setDate(newEnd.getDate() - 7);
        break;
      }
      case 'month': {
        newStart.setDate(newStart.getDate() - 31);
        break;
      }
      case 'lastmonth': {
        newStart.setDate(newStart.getDate() - 62);
        newEnd.setDate(newEnd.getDate() - 31);
        break;
      }
      default: {
        console.warn('Unknown value received in filterByDate');
      }
    }
    this.updateState({
      start: formatDateNumbers(newStart),
      end: formatDateNumbers(newEnd),
      dateType: type,
    });
  };

  render(props, state) {
    return (
      <div id={props.id} className={styles.container}>
        <div className={styles.header}>
          <h3>Avansert søk</h3>
          <button
            className={`${styles.textButton} ${styles.blueText}`}
            onClick={props.toggleSearch}>
            Skjul avansert søk
          </button>
        </div>
        <div className={`${styles.row}`}>
          <div className={styles.flex2}>
            <div className={styles.flex}>
              <div className={`${styles.flex1} ${styles.dateInputContainer}`}>
                <div className={`${styles.padBottom} ${styles.verticalAlign}`}>
                  <span className={`${styles.inlineIcon} ${styles.calendar}`} />
                  Fra
                </div>
                <input
                  onClick={this.toggleDatePicker}
                  className={`${styles.inputMaxWidth} ${styles.input}`}
                  type="text"
                  aria-label="Fra dato, formatert 01.01.2019"
                  value={state.start}
                  onChange={this.changeStart}
                  placeholder="Fra dd.mm.åååå"
                />
              </div>
              <div className={`${styles.flex1}`}>
                <div className={`${styles.padBottom} ${styles.verticalAlign}`}>
                  <span className={`${styles.inlineIcon} ${styles.calendar}`} />
                  Til
                </div>
                <input
                  onClick={this.toggleDatePicker}
                  className={`${styles.inputMaxWidth} ${styles.input}`}
                  type="text"
                  aria-label="Til dato, formatert 01.01.2019"
                  value={state.end}
                  onChange={this.changeEnd}
                  placeholder="Fra dd.mm.åååå"
                />
              </div>
            </div>
            <div className={styles.datePickerContainer}>
              {state.showDatePicker ? (
                <div className={styles.bg} onClick={this.toggleDatePicker} />
              ) : null}
              <div className={styles.datePicker}>
                <DatePicker
                  toggleDatePicker={this.toggleDatePicker}
                  active={state.showDatePicker}
                  onDateSelect={this.onDateSelect}
                />
              </div>
            </div>
          </div>
          <div className={styles.flex1}>
            <div className={`${styles.padBottom} ${styles.verticalAlign}`}>
              <span className={`${styles.inlineIcon} ${styles.hometown}`} />
              Sted
            </div>
            <input
              className={styles.input}
              type="text"
              aria-label="Søk etter sted"
              value={state.city}
              onChange={this.changeCity}
              placeholder="Søk etter sted"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={`${styles.flex2} ${styles.flex} ${styles.flexWrap}`}>
            <button
              onClick={e => this.filterByDate(e, 'today')}
              className={`${styles.button} ${
                this.state.dateType == 'today' ? styles.active : ''
              }`}>
              I dag
            </button>
            <button
              onClick={e => this.filterByDate(e, 'yesterday')}
              className={`${styles.button} ${
                this.state.dateType == 'yesterday' ? styles.active : ''
              }`}>
              I går
            </button>
            <button
              onClick={e => this.filterByDate(e, 'week')}
              className={`${styles.button} ${
                this.state.dateType == 'week' ? styles.active : ''
              }`}>
              Denne uken
            </button>
            <button
              onClick={e => this.filterByDate(e, 'lastweek')}
              className={`${styles.button} ${
                this.state.dateType == 'lastweek' ? styles.active : ''
              }`}>
              Forrige uke
            </button>
            <button
              onClick={e => this.filterByDate(e, 'month')}
              className={`${styles.button} ${
                this.state.dateType == 'month' ? styles.active : ''
              }`}>
              Denne måned
            </button>
            <button
              onClick={e => this.filterByDate(e, 'lastmonth')}
              className={`${styles.button} ${
                this.state.dateType == 'lastmonth' ? styles.active : ''
              }`}>
              Forrige måned
            </button>
          </div>
          <div className={styles.flex1}>
            <div className={`${styles.padBottom} ${styles.verticalAlign}`}>
              <span className={`${styles.inlineIcon} ${styles.newspaper}`} />
              Avis
            </div>
            <div>
              <CheckboxSelect
                options={props.publications}
                selected={state.publication}
                onChange={this.changePublication}
              />
            </div>
          </div>
        </div>

        <div
          className={`${styles.row} ${styles.flexBetween} ${
            styles.flexVerticalCenter
          } ${styles.padTop}`}>
          <div className={styles.flexGrow}>
            <div className={styles.hidden}>
              <RoundedCheckbox
                onChange={this.onToggleSearchFullText}
                checked={state.searchFullText}
                id="searchWholeText"
                label="Søk i hele annonseteksten"
              />
            </div>
          </div>
          <div>
            <button
              onClick={this.resetSearch}
              className={`${styles.textButton} ${styles.grayText}`}>
              Tilbakestill søk
            </button>
            <button
              className={`${styles.button} ${styles.active} ${styles.big}`}
              type="submit">
              Søk
            </button>
          </div>
        </div>
      </div>
    );
  }
}
