import { h, Component } from 'preact';
import queryString from 'query-string';
import { filterEmptyValues } from '../../utils/object';
import { getApiUrl } from '../../utils/hostname';

export function withObituaries(ComposedComponent) {
  class WrapperComponent extends Component {
    state = {
      data: [],
      filters: null,
      total: 0,
    };

    fetchData = (myFilters, offset = 0) => {
      const filteredParameters = filterEmptyValues({
        ...myFilters.filters,
        offset: offset,
      });

      const searchParams = queryString.stringify(filteredParameters);

      return fetch(`${getApiUrl()}/api/memorials?${searchParams}`, {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(obituaries => {
          if (
            obituaries.hasOwnProperty('data') &&
            Array.isArray(obituaries.data)
          ) {
            this.setState({
              data:
                offset > 0
                  ? this.state.data.concat(obituaries.data)
                  : obituaries.data,
              filters: myFilters,
              total: obituaries.total || 0,
            });
          } else {
            console.warn('Error fetching obituaries: ', obituaries);
          }
        });
    };

    fetchMore = () => {
      if (this.state.filters) {
        this.fetchData(this.state.filters, this.state.data.length);
      }
    };

    componentDidMount() {
      const { filters } = this.props;
      this.fetchData({ filters });
    }

    componentWillReceiveProps({ filters }) {
      if (
        Object.entries(filters).toString() !==
        Object.entries(this.props.filters).toString()
      )
        this.fetchData({ filters });
    }

    render(props, state) {
      return (
        <ComposedComponent
          {...props}
          fetchMore={this.fetchMore}
          obituaries={state.data}
          total={state.total}
        />
      );
    }
  }

  return WrapperComponent;
}
