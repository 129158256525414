import { h, Component } from 'preact';
import styles from './style.scss';

export class Masonry extends Component {
  constructor(props) {
    super(props);
    this.state = { columns: 1 };
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  getColumns(w) {
    return (
      this.props.brakePoints.reduceRight((p, c, i) => {
        return c < w ? p : i;
      }, this.props.brakePoints.length) + 1
    );
  }

  onResize() {
    const columns = this.getColumns(this.masonry.offsetWidth);
    if (columns !== this.state.columns) {
      this.setState({ columns: columns });
    }
  }

  mapChildren() {
    const col = [];
    const columnNumber = this.state.columns;
    for (let i = 0; i < columnNumber; i++) {
      col.push([]);
    }
    return this.props.children.reduce((p, c, i) => {
      p[i % columnNumber].push(c);
      return p;
    }, col);
  }

  render() {
    return (
      <div className={styles.masonry} ref={masonry => (this.masonry = masonry)}>
        {this.mapChildren().map((col, ci) => {
          return (
            <div className={styles.column} key={ci}>
              {col.map((child, i) => {
                return <div key={i}>{child}</div>;
              })}
            </div>
          );
        })}
      </div>
    );
  }
}
