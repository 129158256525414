import { h, Component } from 'preact';
import dayjs from 'dayjs';
import '../../sass/style.scss';
import { withFilters } from '../with-filters';
import { withObituaries } from '../with-obituaries';
import { withLocations } from '../with-locations';
import { Form } from '../form';
import { InfoLine } from '../info-line';
import { Obituaries } from '../obituary-container';
import { getPaperName } from '../../utils/hostname';

class App extends Component {
  constructor() {
    super();
    this.state = {
      sortMode: 'date',
      sortDirection: 'asc'
    };
  }

  setSort = (mode, direction) => {
    this.setState({
      sortMode: mode,
      sortDirection: direction,
    });
  };

  sort = () => {
    if (this.state.sortMode === 'date') {
      return this.props.obituaries.sort((a, b) => {
        if (new Date(a.publication_date) < new Date(b.publication_date)) {
          return this.state.sortDirection === 'asc' ? 1 : -1;
        } else if (
          new Date(a.publication_date) > new Date(b.publication_date)
        ) {
          return this.state.sortDirection === 'asc' ? -1 : 1;
        } else {
          return 0;
        }
      });
    } else if (this.state.sortMode === 'name') {
      return this.props.obituaries.sort((a, b) => {
        if (a.person_full_name < b.person_full_name) {
          return this.state.sortDirection === 'asc' ? -1 : 1;
        } else if (a.person_full_name > b.person_full_name) {
          return this.state.sortDirection === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else {
      return this.props.obituaries;
    }
  };

  today = dayjs().format('YYYY-MM-DD');
  yesterday = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  oneWeekAgo = dayjs()
    .subtract(7, 'day')
    .format('YYYY-MM-DD');

  render(props, state) {
    return (
      <div id="obituaries">
        <Form
          onSubmit={props.updateFilters}
          publications={[
            { value: '', label: getPaperName() },
            { value: 'all', label: 'Alle Aviser' },
          ]}
          initialState={props.filters}
        />
        <InfoLine
          hits={props.obituaries.length}
          sort={this.setSort}
          sortMode={this.state.sortMode}
          sortDirection={this.state.sortDirection}
        />
        <Obituaries obituaries={this.sort()} />
        {props.obituaries.length > 0 &&
        props.total > props.obituaries.length ? (
          <div className="showmore">
            <button className="btn btn--primary" onClick={props.fetchMore}>
              Hent flere
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withFilters(withObituaries(withLocations(App)));
