import { h, Component } from 'preact';

const convertLineBreaks = signatures =>
  reorder(
    signatures
      .map(signature =>
        signature.text
          ? signature.text.split('\n').map(text => ({
              text,
            }))
          : [signature],
      )
      .reduce((a, b) => a.concat(b)),
  );

const reorder = entities =>
  entities.map((entity, index) => ({
    ...entity,
    order: index,
  }));

// Groups by align based on the previous value.
const groupByAlign = entities =>
  entities.reduce((acc, item, index, base) => {
    const previous = base[index - 1];

    previous && previous.align === item.align && item.align === 'row'
      ? acc[acc.length - 1].push(item)
      : acc.push([item]);
    return acc;
  }, []);

export class FamilyMembers extends Component {
  render(props) {
    const fields = props.familyMembers;

    const convertToRow = fields
      .filter(({ signatures }) => signatures.length > 0)
      .reduce((acc, item, index, base) => {
        const { align } = item;

        if (align === 'left') {
          const next = base[index + 1];

          // Transform align left + right to center (read: transform data from column => row).
          if (next && next.align && next.align === 'right') {
            const convertToCenter = [
              ...convertLineBreaks(item.signatures),
              ...convertLineBreaks(next.signatures),
            ]
              .reduce((acc, item) => {
                if (!acc[item.order]) acc[item.order] = [];

                acc[item.order].push(item);

                return acc;
              }, [])
              .map((signatures, order) => ({
                align: 'row',
                order,
                signatures: reorder(signatures),
              }));

            acc.push(...convertToCenter);
          } else {
            acc.push(item);
          }
        }

        if (align === 'right') {
          const previous = base[index - 1];

          if (previous && previous.align && previous.align !== 'left') {
            acc.push(item);
          }
        }

        if (!['left', 'right'].includes(align)) {
          acc.push(item);
        }

        return acc;
      }, []);

    const classes = ['family_members'];

    if (
      [
        'Bergens Tidende',
        'Vestnytt',
        'Askøyværingen',
        'Bygdanytt',
        'Strilen',
      ].includes(props.source_name)
    )
      classes.push('family_members--centered');

    return (
      <div class={classes.join(' ')}>
        {groupByAlign(convertToRow).map(row => (
          <div class="wrapper">
            {row
              .filter(({ signatures }) => signatures.length > 0)
              .map(({ align, signatures }) => (
                <div
                  class={`family_members__item family_members__item--${align}`}
                  dangerouslySetInnerHTML={{
                    __html: signatures
                      .map(({ order, text, relation, spacer }) => {
                        const elements = [];

                        if (text && text.length) {
                          elements.push(text);
                        }

                        if (relation && text.relation) {
                          elements.push(relation);
                        }

                        if (spacer === true && !['row'].includes(align)) {
                          elements.push('<br>');
                        }

                        return elements.map(
                          entity =>
                            entity &&
                            entity
                              .split('\n')
                              .map(
                                line =>
                                  `<div class="family_members__entity family_members__entity--order-${order}">${line ||
                                    '&nbsp;'}</div>`,
                              )
                              .reduce((a, b) => a.concat(b)),
                        );
                      })
                      .reduce((a, b) => a.concat(b))
                      .join(''),
                  }}
                ></div>
              ))}
          </div>
        ))}
      </div>
    );
  }
}
