// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container__1x2yG{font-size:16px;color:#6f6f6f}.mainInput__3z9Db{width:400px;max-width:90vw;border-radius:50px;height:60px;line-height:60px;background:white;border:1px solid #6f6f6f;text-indent:30px}.centerSearch__3X5Uw{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.positioner__534vu{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;z-index:9}.iconButton__pU-5Z{background:none;border:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.icon__3a4nB{width:45px;height:60px;background-repeat:no-repeat;background-size:30px;background-position:center}.smallIcon__2M9SW{display:inline-block;width:25px;height:15px;background-repeat:no-repeat;background-size:15px;background-position:bottom}.searchButton__1iXxk{position:absolute;top:0px;right:70px}.search__3S3rI{background-image:url(https://cdn.obituaries.schibsted.media/icons/search.svg)}.advancedButton__FzSri{position:absolute;top:0px;right:25px}.advancedBg__pGXwy{background-image:url(https://cdn.obituaries.schibsted.media/icons/advanced.svg)}.tips__2Pw09{padding:8px 0;text-align:center}@media screen and (max-width: 500px){.tips__2Pw09{font-size:0.7rem}}\n", ""]);
// Exports
exports.locals = {
	"container": "container__1x2yG",
	"mainInput": "mainInput__3z9Db",
	"centerSearch": "centerSearch__3X5Uw",
	"positioner": "positioner__534vu",
	"iconButton": "iconButton__pU-5Z",
	"icon": "icon__3a4nB",
	"smallIcon": "smallIcon__2M9SW",
	"searchButton": "searchButton__1iXxk",
	"search": "search__3S3rI",
	"advancedButton": "advancedButton__FzSri",
	"advancedBg": "advancedBg__pGXwy",
	"tips": "tips__2Pw09"
};
module.exports = exports;
