const getApiUrl = () => {
  const host = window.parent.location.host;
  if (host.indexOf('localhost') >= 0) return `http://localhost:5050`;

  return `https://${window.parent.location.host.replace('www', 'obituaries')}`;
};

const getPaperName = () => {
  switch (window.parent.location.host) {
    case 'www.fvn.no':
      return 'Fædrelandsvennen';
    default:
      return window.parent.location.host.replace('www.', '');
  }
};

export { getApiUrl, getPaperName };
