import { h, Component } from 'preact';
import styles from './style.scss';

export class InfoLine extends Component {
  render(props, state) {
    return (
      <div className={styles.container}>
        <div>{props.hits ? `${props.hits} treff` : ''}</div>
        <div className={styles.sortContainer}>
          <div>
            <span>Dato</span>
            <button
              className={styles.iconButton}
              onClick={() => props.sort('date', 'desc')}
              aria-label="Sorter resultater etter dato, nyeste først">
              <span
                className={`${styles.icon} ${
                  props.sortMode === 'date' && props.sortDirection === 'desc'
                    ? styles.sortDescendingBlue
                    : styles.sortDescendingGray
                }`}
              />
            </button>
            <span>-</span>
            <button
              className={styles.iconButton}
              onClick={() => props.sort('date', 'asc')}
              aria-label="Sorter resultater etter dato, eldste først">
              <span
                className={`${styles.icon} ${
                  props.sortMode === 'date' && props.sortDirection === 'asc'
                    ? styles.sortAscendingBlue
                    : styles.sortAscendingGray
                }`}
              />
            </button>
          </div>
          <div>
            <span>Navn</span>
            <button
              className={styles.iconButton}
              onClick={() => props.sort('name', 'asc')}
              aria-label="Sorter resultater etter navn, a til å">
              <span
                className={`${styles.icon} ${styles.bottom} ${
                  props.sortMode === 'name' && props.sortDirection === 'asc'
                    ? styles.sortAlphabetAscendingBlue
                    : styles.sortAlphabetAscendingGray
                }`}
              />
            </button>
            <span>-</span>
            <button
              className={styles.iconButton}
              onClick={() => props.sort('name', 'desc')}
              aria-label="Sorter resultater etter navn, å til a">
              <span
                className={`${styles.icon} ${styles.bottom} ${
                  props.sortMode === 'name' && props.sortDirection === 'desc'
                    ? styles.sortAlphabetDescendingBlue
                    : styles.sortAlphabetDescendingGray
                }`}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
