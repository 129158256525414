import { h, Component } from 'preact';
import { Obituary } from '../obituary';
import { Masonry } from '../masonry';

export class Obituaries extends Component {
  render(props) {
    return (
      <Masonry brakePoints={[640, 768, 1024]}>
        {props.obituaries.map(obituary => (
          <Obituary data={obituary} />
        ))}
      </Masonry>
    );
  }
}
