// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro);"]);
// Module
exports.push([module.id, ".item__3ZhiA{font-family:'Source Serif Pro', serif;display:inline-block;margin:8px 12px;width:100%;max-width:250px;min-width:250px;-webkit-box-shadow:0px 2px 6px 1px #bdbdbd;box-shadow:0px 2px 6px 1px #bdbdbd;border-radius:6px;background:white;text-align:center;overflow:hidden;line-height:1}.item__3ZhiA p,.item__3ZhiA div{line-height:1;white-space:pre-line}.item__3ZhiA .content__3Z0Ww{padding:32px 16px}.item__3ZhiA .paperInfo__36S52{font-size:12px;line-height:20px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.item__3ZhiA .funeralAgencyName__2VmLY{font-size:12px}.item__3ZhiA .agencyName__39bby{font-size:1rem}.item__3ZhiA .nameContainer__1OLhV{margin-bottom:12px}.item__3ZhiA .name__1rTqM{font-family:sans-serif;font-size:18px;font-weight:bold;line-height:1;margin:0;padding:0}.item__3ZhiA .headline__1TU5R{margin:8px 0}.item__3ZhiA .actions__3ymO_{margin-top:24px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.item__3ZhiA .actions__item__2Ouh9 a{color:black;text-decoration:underline;font-size:12px}.item__3ZhiA .actions__item--right__2Pa8Z{margin-left:auto}.btn-active__FAQc9>svg{color:#234b64}\n", ""]);
// Exports
exports.locals = {
	"item": "item__3ZhiA",
	"content": "content__3Z0Ww",
	"paperInfo": "paperInfo__36S52",
	"funeralAgencyName": "funeralAgencyName__2VmLY",
	"agencyName": "agencyName__39bby",
	"nameContainer": "nameContainer__1OLhV",
	"name": "name__1rTqM",
	"headline": "headline__1TU5R",
	"actions": "actions__3ymO_",
	"actions__item": "actions__item__2Ouh9",
	"actions__item--right": "actions__item--right__2Pa8Z",
	"btn-active": "btn-active__FAQc9"
};
module.exports = exports;
