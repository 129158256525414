// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".citation__2Cy7r{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column;flex-flow:column;margin:1rem 0;font-style:italic}.citation__2Cy7r .author__3T9i1{margin-top:1rem;text-align:right}\n", ""]);
// Exports
exports.locals = {
	"citation": "citation__2Cy7r",
	"author": "author__3T9i1"
};
module.exports = exports;
