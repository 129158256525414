import 'dayjs/locale/nb';
import dayjs from 'dayjs';

dayjs.locale('nb');

const humanize = date => formatDate(date, 'DD. MMMM YYYY');

const formatDate = function(dateString, format) {
  const date = dayjs(dateString);

  if (!date.isValid()) return '';

  return date.format(format);
};

const formatDateNumbers = (date, divider = '.') =>
  formatDate(date, `DD${divider}MM${divider}YYYY`);

const formatDateISO = date => formatDate(date, 'YYYY-MM-DD');

const stringToDateObject = str => {
  if (!str) return null;

  return new Date(
    str
      .split('.')
      .reverse()
      .join('/'),
  );
};

export {
  stringToDateObject,
  formatDateNumbers,
  formatDateISO,
};

export default humanize;
